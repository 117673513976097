import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./NavbarSection.module.css";
import Settings from "../settings/Settings";

const NavbarSection = ({
  messagePositive,
  setExpandBankConnectionsAmount,
  expandBankConnectionsAmount,
  requisitionLength,
  amountOfRequisitions,
  isRequisitionLimitReached,
  handleAddAccountClick,
  expandUrls,
  setExpandUrls,
  MayGetExtraUrlForEachAccountNumberForExcel,
  handleExcelInstructionsClick,
  excelInstructions,
  click1,
  click2,
  click3,
  click4,
  click5,
  click7,
  click8,
  click9,
  click10,
  accountIds,
  userId,
  accounts,
  handlePositiveMessageOnClick,
  message,
  showCopyUrlSection, // prop to control the visibility
  showConnectionLimits, // prop to control the visibility
}) => {
  const navigate = useNavigate();

  return (
    <div className={styles.navbarSection}>
      <div className={styles.navbar}>
        <h2 className={styles.header}>Bank Data</h2>
        <Settings />
        <div className={styles.addAnotherAccount}>
          <button
            className={styles.logoutBtn}
            onClick={() => {
              localStorage.removeItem("token");
              navigate("/Login");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 25 20"
              fill="currentColor"
              className={styles.logoutIcon}
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M10.09 15.59L8.67 17l-5-5 5-5 1.41 1.41L6.83 11H18v2H6.83l3.26 3.59zM20 3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H4V5h16v14z" />
            </svg>
            <p>Logout</p>
          </button>
        </div>
      </div>
      {messagePositive && (
        <div className={styles.messagePositive}>
          <p>{messagePositive}</p>
        </div>
      )}

      <div className={styles.headerBox}>
        {showConnectionLimits && (
          <div
            onClick={() =>
              setExpandBankConnectionsAmount(!expandBankConnectionsAmount)
            }
          >
            <p className={styles.connectionsAmount}>Add Account /See Limit</p>
            {expandBankConnectionsAmount && (
              <div className={styles.requisitionContainer}>
                <div className={styles.requisitionLength}>
                  <div className={styles.requisitionInfo}>
                    <p>
                      <strong>Requisition Amount:</strong> {requisitionLength}
                    </p>

                    <p>
                      <strong>Requisition Limit:</strong> {amountOfRequisitions}
                    </p>
                  </div>
                  {!isRequisitionLimitReached && (
                    <div className={styles.addAnotherAccountBtn}>
                      <button onClick={() => navigate("/ConnectToBank")}>
                        Add Another Account
                      </button>
                    </div>
                  )}
                  {isRequisitionLimitReached && (
                    <div className={styles.addAnotherAccountBtn}>
                      <button onClick={handleAddAccountClick}>
                        Add Another Account
                      </button>
                    </div>
                  )}
                  {isRequisitionLimitReached && (
                    <div className={styles.contactSupportMessage}>
                      <p>
                        You have reached the limit. Contact support to increase
                        the limit.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        {showCopyUrlSection && (
          <div className={styles.copyUrlBox}>
            <div
              className={styles.copyUrlTitle}
              onClick={() => setExpandUrls(!expandUrls)}
            >
              ⬇️ Copy url for Excel Data Import ⬇️
            </div>

            {MayGetExtraUrlForEachAccountNumberForExcel && expandUrls && (
              <div className={styles.accountIds}>
                <div className={styles.excelInstructions}>
                  <div className={styles.instructions}>
                    <p>To import the data into Excel, follow these steps:</p>
                    <ol className={styles.instructionsList}>
                      <li>
                        Copy the URL of the account you want to import by
                        clicking on it.
                      </li>
                      <li>
                        <ol>
                          {accountIds.map((id) => (
                            <li
                              className={styles.accountId}
                              key={id}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `https://kleinmanshloma-open-banking-server.onrender.com/api/v1/transactions/${userId}/${id}`
                                );
                                handlePositiveMessageOnClick(
                                  `${
                                    accounts.find(
                                      (account) => account.id === id
                                    ).iban || id
                                  } URL copied to clipboard`
                                );
                              }}
                            >
                              <span>
                                Account Number / Iban{" "}
                                {accounts.find((account) => account.id === id)
                                  .iban || id}
                              </span>
                              <span className={styles.copyIcon}>📋</span>
                            </li>
                          ))}
                        </ol>
                        {accountIds?.length > 0 && (
                          <ul className={styles.allInOneSheet}>
                            <li
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `https://kleinmanshloma-open-banking-server.onrender.com/api/v1/transactions/${userId}`
                                );
                                handlePositiveMessageOnClick(
                                  `All accounts URL copied to clipboard`
                                );
                              }}
                            >
                              <span>All in one sheet</span>
                              <span className={styles.copyIcon}>📋</span>
                            </li>
                          </ul>
                        )}
                      </li>
                      <li>
                        In Excel, go to the "Data" tab and click on "Get Data".
                        <img
                          className={styles.imageInstructions}
                          src={click1}
                          alt="click1"
                        />
                      </li>
                      <li>
                        Choose "From Web".
                        <img
                          className={styles.imageInstructions}
                          src={click2}
                          alt="click2"
                        />
                      </li>
                      <li>
                        Paste the URL into the box and click "OK". To import the
                        data into Excel.
                        <img
                          className={styles.imageInstructions}
                          src={click3}
                          alt="click3"
                        />
                      </li>
                      <li>
                        Rename the query to the account number IBAN or any
                        meaningful name. And Then Click Into Table.
                        <img
                          className={styles.imageInstructions}
                          src={click4}
                          alt="click4"
                        />
                      </li>
                      <li>
                        Remove The first row and Expand the second By clicking
                        on the 2 arrows.
                        <img
                          className={styles.imageInstructions}
                          src={click5}
                          alt="click5"
                        />
                      </li>
                      <li>
                        Click on the expand To New Rows.
                        <img
                          className={styles.imageInstructions}
                          src={click7}
                          alt="click7"
                        />
                      </li>
                      <li>
                        Click on the expand To New Col.
                        <img
                          className={styles.imageInstructions}
                          src={click8}
                          alt="click8"
                        />
                      </li>
                      <li>
                        Click To Oncheck The _id and Click To Oncheck Use
                        original column name as prefix. And Click OK.
                        <img
                          className={styles.imageInstructions}
                          src={click9}
                          alt="click9"
                        />
                      </li>
                      <li>
                        Click Close & Load To Finish.
                        <img
                          className={styles.imageInstructions}
                          src={click10}
                          alt="click10"
                        />
                      </li>
                      <li>
                        You are all done. Now you can update with just Clicking
                        Refresh in the Data Tab.
                      </li>
                      <button
                        className={styles.excelInstructionsBtn}
                        onClick={() => setExpandUrls(!expandUrls)}
                      >
                        {excelInstructions ? "Hide" : "Show"} Instructions
                      </button>
                    </ol>
                  </div>
                </div>
              </div>
            )}
            {message && (
              <div className={styles.requisitionLimitReached}>
                <p>{message}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NavbarSection;
