import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TransactionTable from "./TransactionTable";
import styles from "./BankDataComponent.module.css";
import config from "../../config/config";
import NavbarSection from "../Navbar/NavbarSection";

// Importing images
import click1 from "../images/Click1.png";
import click2 from "../images/Click2.png";
import click3 from "../images/click3.png";
import click4 from "../images/click4.png";
import click5 from "../images/Step 5 - 6.png";
import click7 from "../images/step 7.png";
import click8 from "../images/step 8.png";
import click9 from "../images/step 9.png";
import click10 from "../images/step 10.png";

const BankAccountsData = () => {
  const navigate = useNavigate();

  const [accounts, setAccounts] = useState([]);
  const [accountIds, setAccountIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [expandedInstitution, setExpandedInstitution] = useState(null);
  const [expandedIBAN, setExpandedIBAN] = useState(null);
  const [transactions, setTransactions] = useState({});
  const [expandedTransaction, setExpandedTransaction] = useState(null);
  const [expandUrls, setExpandUrls] = useState(false);
  const [expandBankConnectionsAmount, setExpandBankConnectionsAmount] =
    useState(false);
  const [searchTransaction, setSearchTransaction] = useState("");

  const [messagePositive, setMessagePositive] = useState(null);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [excelInstructions, setExcelInstructions] = useState(false);

  const handleExcelInstructionsClick = () => {
    setExcelInstructions(!excelInstructions);
  };

  const handleSearchTransaction = (e) => {
    setSearchTransaction(e.target.value);
  };

  const toggleInstitution = (institutionId) => {
    setExpandedTransaction(null);
    setExpandedInstitution(
      expandedInstitution === institutionId ? null : institutionId
    );
  };

  const toggleIBAN = async (iban) => {
    setExpandedTransaction(false);
    if (expandedIBAN === iban) {
      setExpandedIBAN(null);
      setSearchTransaction(""); // Reset search term when IBAN is closed
    } else {
      setExpandedIBAN(iban);
      if (!transactions[iban]) {
        setTransactionLoading(true);
        const findAccount = accounts.find((account) => account.iban === iban);
        const accountId = findAccount.id;
        try {
          const response = await fetch(
            `${config.URL_PROD}/api/v1/bank/transactionsAsked/${accountId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          if (!response.ok) {
            throw new Error(
              `Failed to fetch transactions: ${response.statusText}`
            );
          }
          const result = await response.json();
          setTransactions((prevTransactions) => ({
            ...prevTransactions,
            [iban]: result.transactionsDataWithMerchant,
          }));
        } catch (error) {
          console.error("Error fetching transactions:", error.message);
        } finally {
          setTransactionLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${config.URL_PROD}/api/v1/bank/getAccountsAndBalances`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.statusText}`);
        }

        const result = await response.json();

        if (
          result.message &&
          result.message?.startsWith("Your requisitions have expired.")
        ) {
          return setErrorMessage(result.message);
        }
        if (result.message?.startsWith("Failed to fetch account")) {
          return setErrorMessage(result.message);
        }

        if (result.message === "No accounts with balances found") {
          setErrorMessage(
            "Sorry but we are working on the issue and will be back soon"
          );
          const timer = setTimeout(() => {
            setErrorMessage(null);
          }, 1500);
          return setAccounts([]);
        }

        setAccounts(result);
        setAccountIds(result.map((account) => account.id));
      } catch (error) {
        console.error("Error fetching data:", error.message);
        if (error.message === "Failed to fetch data: Unauthorized") {
          navigate("/Login");
        } else {
          setErrorMessage(error.message);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [navigate]);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (errorMessage) {
    return (
      <div className={styles.errorMessage}>
        <button
          className={styles.errorCloseBtn}
          onClick={() => setErrorMessage(null)}
        >
          X
        </button>
        <p>{errorMessage}</p>
      </div>
    );
  }

  const groupedAccounts = accounts?.reduce((acc, account) => {
    if (!acc[account.institution_id]) {
      acc[account.institution_id] = [];
    }
    acc[account.institution_id].push(account);
    return acc;
  }, {});

  const userId = localStorage.getItem("userId");
  const amountOfRequisitions = localStorage.getItem("amountOfRequisitions");
  const requisitionLength = localStorage.getItem("Requisition Length");
  const isRequisitionLimitReached = amountOfRequisitions === requisitionLength;
  const MayGetExtraUrlForEachAccountNumberForExcel =
    localStorage.getItem("getExtraUrlForEachAccountNumberForExcel") === "true";

  const handleAddAccountClick = () => {
    setMessage(
      "You have reached the limit. Contact support to increase the limit."
    );
    setTimeout(() => {
      setMessage(null);
    }, 4000);
  };

  const handlePositiveMessageOnClick = (message) => {
    setMessagePositive(message);
    setTimeout(() => {
      setMessagePositive(null);
    }, 2000);
  };

  const getCurrencySymbol = (currency) => {
    return currency
      ? new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: currency,
        })
          .format(0)
          .replace(/\d/g, "")
          .replace(/\./g, "")
      : "N/A";
  };

  return (
    <div className={styles.main}>
      <NavbarSection
        showCopyUrlSection={true}
        showConnectionLimits={true}
        messagePositive={messagePositive}
        setExpandBankConnectionsAmount={setExpandBankConnectionsAmount}
        expandBankConnectionsAmount={expandBankConnectionsAmount}
        requisitionLength={requisitionLength}
        amountOfRequisitions={amountOfRequisitions}
        isRequisitionLimitReached={isRequisitionLimitReached}
        handleAddAccountClick={handleAddAccountClick}
        expandUrls={expandUrls}
        setExpandUrls={setExpandUrls}
        MayGetExtraUrlForEachAccountNumberForExcel={
          MayGetExtraUrlForEachAccountNumberForExcel
        }
        handleExcelInstructionsClick={handleExcelInstructionsClick}
        excelInstructions={excelInstructions}
        click1={click1}
        click2={click2}
        click3={click3}
        click4={click4}
        click5={click5}
        click7={click7}
        click8={click8}
        click9={click9}
        click10={click10}
        accountIds={accountIds}
        userId={userId}
        accounts={accounts}
        handlePositiveMessageOnClick={handlePositiveMessageOnClick}
        message={message}
      />

      <div className={styles.mainContainer}>
        <div className={styles.bankAccountsContainer}>
          {Object.keys(groupedAccounts).map((institutionId) => (
            <div key={institutionId} className={styles.bankAccountSection}>
              <div className={styles.mainContainerBankAccounts}>
                <div className={styles.container}>
                  <h3
                    className={styles.title}
                    onClick={() => {
                      toggleInstitution(institutionId);
                    }}
                  >
                    <span className={styles.bankHeader}>
                      Bank Name{" "}
                      {groupedAccounts[institutionId][0].bankName || "N/A"}:
                    </span>{" "}
                    <span>
                      <img
                        className={styles.logo}
                        src={
                          groupedAccounts[institutionId][0].bankLogo || "N/A"
                        }
                        alt="Bank Logo"
                        title="Bank Logo"
                      />
                    </span>
                    <div>
                      <strong>Total Balance:</strong>{" "}
                      {groupedAccounts[institutionId]
                        .reduce(
                          (acc, account) =>
                            acc +
                            Number(
                              account.balanceAmount?.amount ||
                                account.balanceAmount
                            ),
                          0
                        )
                        ?.toLocaleString("en-BE", {
                          style: "currency",
                          currency: "EUR",
                        })
                        .replace(/\.00$/, "")}
                    </div>
                    <p>
                      <strong>Accounts Iban:</strong>{" "}
                      {groupedAccounts[institutionId].map((account) => {
                        return (
                          <div key={account.id}>
                            <p>{account.iban}</p>
                            <div className={styles.removeAccountBtn}>
                              <button
                                onClick={async (e) => {
                                  if (
                                    !window.confirm(
                                      "Are you sure you want to remove this account?" +
                                        " " +
                                        account.iban +
                                        " " +
                                        "Once removed, the account will be deleted permanently."
                                    )
                                  ) {
                                    return;
                                  }

                                  e.stopPropagation();
                                  try {
                                    const response = await fetch(
                                      `${config.URL_PROD}/api/v1/bank/removeAccount`,
                                      {
                                        method: "POST",
                                        headers: {
                                          "Content-Type": "application/json",
                                          Authorization: `Bearer ${localStorage.getItem(
                                            "token"
                                          )}`,
                                        },
                                        body: JSON.stringify({
                                          account_iban: account.iban,
                                        }),
                                      }
                                    );

                                    if (!response.ok) {
                                      throw new Error(
                                        `Failed to remove account: ${response.statusText}`
                                      );
                                    }

                                    const result = await response.json();
                                    if (result.message) {
                                      setErrorMessage(result.message);
                                    }

                                    window.location.href = window.location.href;
                                  } catch (error) {
                                    console.error(
                                      "Error removing account:",
                                      error.message
                                    );
                                    setErrorMessage(error.message);
                                  }
                                }}
                              >
                                Remove Account
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </p>
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.expandedDetailsContainer}>
          {Object.keys(groupedAccounts).map(
            (institutionId) =>
              expandedInstitution === institutionId && (
                <div
                  key={institutionId}
                  className={styles.mainContainerBankAccountsDetails}
                >
                  {groupedAccounts[institutionId].map((account) => (
                    <div
                      key={account.id}
                      className={styles.BankAccountsDataContainer}
                    >
                      <div className={styles.bankData}>
                        <div className={styles.accountBicName}>
                          <div className={styles.accountDetails}>
                            <div className={styles.balanceRow}>
                              <strong>Balance:</strong>{" "}
                              {getCurrencySymbol(
                                account?.currency ||
                                  account.balanceAmount?.currency
                              ) +
                                +Number(
                                  account.balanceAmount?.amount ||
                                    account?.balanceAmount
                                )}
                            </div>
                            <div className={styles.balanceRow}>
                              <strong>Account Currency:</strong>{" "}
                              {account?.balanceAmount?.currency ||
                                account?.currency}
                            </div>
                          </div>
                          <span>
                            <strong>Institution ID / Bic:</strong>{" "}
                            {account.institution_id}
                          </span>
                        </div>
                        <div
                          onClick={() => toggleIBAN(account.iban)}
                          className={`${styles.accountItem} ${
                            expandedIBAN === account.iban ? styles.expanded : ""
                          }`}
                        >
                          <div
                            className={styles.ibanBAnkSearch}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <span
                              className={styles.btnForTransactions}
                              onClick={() => toggleIBAN(account.iban)}
                            >
                              <strong>IBAN:</strong> {account.iban}
                            </span>
                            {expandedIBAN === account.iban && (
                              <span className={styles.searchTransaction}>
                                <input
                                  type="text"
                                  placeholder="Search Transaction"
                                  className={styles.searchTransactionInput}
                                  value={searchTransaction}
                                  onChange={(e) => {
                                    setSearchTransaction(e.target.value);
                                  }}
                                  onClick={(e) => e.stopPropagation()}
                                />
                              </span>
                            )}
                          </div>
                          {expandedIBAN === account.iban && (
                            <div className={styles.accountItem}>
                              {transactionLoading &&
                              expandedIBAN === account.iban ? (
                                <p>Loading transactions...</p>
                              ) : (
                                expandedIBAN === account.iban &&
                                transactions[account.iban] && (
                                  <TransactionTable
                                    transactions={transactions}
                                    account={account}
                                    handleSearchTransaction={searchTransaction}
                                  />
                                )
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default BankAccountsData;
