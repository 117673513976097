import React, { useState, useEffect } from "react";
import styles from "./transactionStyles.module.css";

const parseAdditionalInformation = (additionalInformation) => {
  try {
    // return JSON.parse(additionalInformation); if the additional information is in JSON format then parse it as JSON object else fallback to custom parsing logic
    if (typeof additionalInformation === "object") {
      return JSON.parse(additionalInformation);
    } else {
      const infoArray = additionalInformation?.split(", ").map((info) => {
        return info.replace(/[\[\]']+/g, "");
      });
      const infoObject = {};

      infoArray?.forEach((info) => {
        const [key, value] = info.split(": ");
        infoObject[key] = value;
      });

      return infoObject;
    }
  } catch (error) {
    console.error("Failed to parse additional information as JSON:", error);
    // Fallback to custom parsing logic
    const infoArray = additionalInformation?.split(", ").map((info) => {
      return info.replace(/[\[\]']+/g, "");
    });
    const infoObject = {};

    infoArray?.forEach((info) => {
      const [key, value] = info.split(": ");
      infoObject[key] = value;
    });

    return infoObject;
  }
};

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 950);
    };

    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);

    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  return isMobile;
};

const TransactionTable = ({
  transactions,
  account,
  handleSearchTransaction,
}) => {
  console.log("transactions", transactions);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [filteredTransactions, setFilteredTransactions] = useState([]);

  const toggleAdditionalInformation = (e, transaction) => {
    e.stopPropagation();
    setSelectedTransaction(transaction);
  };

  const closeModal = (e) => {
    e.stopPropagation();
    setSelectedTransaction(null);
  };

  const isMobile = useIsMobile();

  // handle the search to filter the transactions
  useEffect(() => {
    if (!handleSearchTransaction) {
      setFilteredTransactions(transactions[account.iban]);
    } else {
      const trimmedSearchTerm = handleSearchTransaction.trim();
      setFilteredTransactions(
        transactions[account.iban].filter((transaction) => {
          const additionalInfo = parseAdditionalInformation(
            transaction?.additionalInformation
          );
          const merchant =
            transaction?.Merchant ||
            transaction?.creditorName ||
            transaction?.debtorName ||
            transaction?.proprietaryBankTransactionCode?.replace(/_/g, " ") ||
            transaction?.remittanceInformationUnstructuredArray ||
            (additionalInfo?.narrative?.split(", ")[0] ===
            "BETALING MET DEBETKAART"
              ? "BETALING MET DEBETKAART" +
                (isMobile ? "" : " " + additionalInfo.atmPosName)
              : additionalInfo?.narrative?.split(", ")[0]);

          const merchantName =
            typeof merchant === "string"
              ? merchant.replace(/'/g, "")
              : "Unknown Merchant";

          const transactionAmount =
            transaction?.transactionAmount?.amount?.toString() || "";

          return (
            merchantName
              .toLowerCase()
              .includes(trimmedSearchTerm.toLowerCase()) ||
            transactionAmount.includes(trimmedSearchTerm)
          );
        })
      );
    }
  }, [handleSearchTransaction, transactions, account.iban, isMobile]);

  return (
    <div className={styles.transactionContainer}>
      <table className={styles.transactionTable}>
        <thead>
          <tr>
            <th>Merchant</th>
            <th>Transaction Date</th>
            <th>Amount</th>
            <th>Currency</th>
          </tr>
        </thead>
        <tbody>
          {filteredTransactions.map((transaction, transactionIndex) => {
            const additionalInfo = parseAdditionalInformation(
              transaction?.additionalInformation
            );
            const merchant =
              transaction?.Merchant ||
              transaction?.creditorName ||
              transaction?.debtorName ||
              transaction?.proprietaryBankTransactionCode?.replace(/_/g, " ") ||
              transaction?.remittanceInformationUnstructuredArray ||
              (additionalInfo?.narrative?.split(", ")[0] ===
              "BETALING MET DEBETKAART"
                ? "BETALING MET DEBETKAART" +
                  (isMobile ? "" : " " + additionalInfo.atmPosName)
                : additionalInfo?.narrative?.split(", ")[0]);

            const merchantName =
              typeof merchant === "string"
                ? merchant.replace(/'/g, "")
                : "Unknown Merchant";

            const formattedMerchantName =
              merchantName.charAt(0).toUpperCase() +
              merchantName.slice(1).toLowerCase();

            return (
              <tr
                key={transactionIndex}
                className={styles.transactionItem}
                onClick={(e) => {
                  toggleAdditionalInformation(e, transaction);
                }}
              >
                <td>{formattedMerchantName}</td>
                <td>{transaction.bookingDate}</td>
                <td>{transaction.transactionAmount?.amount}</td>
                <td>{transaction.transactionAmount?.currency}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {filteredTransactions.length < 1 && (
        <h2 className={styles.noTransactionsFound}>No transactions found</h2>
      )}

      {selectedTransaction && (
        <div
          className={styles.modal}
          onClick={(e) => {
            e.stopPropagation();
            closeModal(e);
          }}
        >
          <div className={styles.modalContent}>
            <span
              className={styles.close}
              onClick={(e) => {
                e.stopPropagation();
                closeModal(e);
              }}
            >
              &times;
            </span>
            <h2>Additional Information</h2>
            {selectedTransaction?.currencyExchange && (
              <div className={styles.moreInfoBox}>
                {console.log(selectedTransaction.currencyExchange)}
                <span className={styles.upperCase}>
                  Currency Exchange Details
                </span>
                <span className={styles.lowercase}>
                  {`Source Currency: ${selectedTransaction.currencyExchange.sourceCurrency}`}
                  <br />
                  {`Exchange Rate: ${selectedTransaction.currencyExchange.exchangeRate}`}
                  <br />
                  {`Target Currency: ${selectedTransaction.currencyExchange.targetCurrency}`}
                </span>
              </div>
            )}
            {selectedTransaction?.additionalInformation ? (
              <>
                <pre onClick={(e) => e.stopPropagation()}>
                  {Object.keys(
                    parseAdditionalInformation(
                      selectedTransaction?.additionalInformation
                    )
                  ).map((key, index) => {
                    return (
                      <div key={index} className={styles.moreInfoBox}>
                        <span className={styles.upperCase}>{key} </span>
                        <span className={styles.lowercase}>
                          {
                            parseAdditionalInformation(
                              selectedTransaction?.additionalInformation
                            )[key]
                          }
                        </span>
                      </div>
                    );
                  })}
                </pre>
                {console.log(
                  parseAdditionalInformation(
                    selectedTransaction?.additionalInformation
                  )
                )}
              </>
            ) : (
              <pre
                onClick={(e) => e.stopPropagation()}
                className={styles.moreInfoBox}
              >
                {selectedTransaction.remittanceInformationUnstructured &&
                  `Remittance Information: ${selectedTransaction.remittanceInformationUnstructured}`}
                {selectedTransaction.proprietaryBankTransactionCode &&
                  `Proprietary Bank Transaction Code: ${selectedTransaction.proprietaryBankTransactionCode}`}
              </pre>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TransactionTable;
/*"Moved From your GBP balance"*/
