import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import styles from "./login.module.css";

import config from "../../config/config";

const Login = ({ userData, setUserData }) => {
  const [response, setResponse] = useState(null);
  const [errMessage, setErrMessage] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (response || errMessage) {
      const timer = setTimeout(() => {
        setResponse(null);
        setErrMessage(null);
      }, 4000);

      return () => clearTimeout(timer); // Clear timeout if response or errMessage changes
    }
  }, [response, errMessage]);

  const userDataSubmited = async (e) => {
    e.preventDefault();
    // console.log("User data submitted", userData);

    const results = await fetch(`${config.URL_PROD}/api/v1/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    // if status 400 than reload the page
    if (results.status === 400) {
      window.location.reload();
      setErrMessage("Something went wrong, please try again");
    }

    // Check if the response status is 429 (Too Many Requests)
    if (results.status === 429) {
      const text = await results.text();
      setErrMessage(text);
      return;
    }

    // Try to parse the response as JSON
    const data = await results.json().catch(() => null);

    if (
      data?.error ||
      results.statusText === "Internal Server Error" ||
      results.statusText === "Unauthorized" ||
      results.statusText === "Bad Request" ||
      !data
    ) {
      setErrMessage(
        data?.message || data?.error || "Unexpected error occurred"
      );
      return;
    }

    // Save UserID from the data.requisition.User in local storage
    const userId = data?.User;
    localStorage.setItem("userId", userId);
    localStorage.setItem("Requisition Length", data?.Requisition);
    localStorage.setItem("amountOfRequisitions", data?.amountOfRequisitions);
    localStorage.setItem(
      "getExtraUrlForEachAccountNumberForExcel",
      data?.getExtraUrlForEachAccountNumberForExcel
    );

    setResponse(data);

    if (data.token && data.Requisition) {
      localStorage.setItem("token", data.token);
      return navigate("/BankAccountsData");
    }

    if (data.token) {
      localStorage.setItem("token", data.token);
      return navigate("/ConnectToBank");
    }
  };

  const forgotPassword = async (e) => {
    // stop the form from submitting
    e.preventDefault();

    try {
      const results = await fetch(`${config.URL_PROD}/generate-link`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (!results.ok) {
        throw new Error("Failed to send reset password link");
      }

      const responseOk = await results.text();
      console.log("responseOk", responseOk);
      setResponse({ message: responseOk });
    } catch (error) {
      console.error("Error sending reset password link:", error);
      setErrMessage("Failed to send reset password link");
    }
  };

  return (
    <div className={styles.loginPage}>
      <h1 className={styles.loginHeader}>
        <b>Bank Accounts in Excel</b>
      </h1>
      <div className={styles.loginBox}>
        {errMessage && <p className={styles.errorMessage}>{errMessage}</p>}
        {response && (
          <p className={styles.responseMessage}>{response.message}</p>
        )}
        <div className={styles.illustrationWrapper}>
          <img
            src="https://mixkit.imgix.net/art/preview/mixkit-left-handed-man-sitting-at-a-table-writing-in-a-notebook-27-original-large.png?q=80&auto=format%2Ccompress&h=700"
            alt="Login"
          />
        </div>
        <form onSubmit={userDataSubmited} className={styles.loginForm}>
          <div name="login-form" initialValues={{ remember: true }}>
            <div className={styles.welcomeMessage}>
              <p>Welcome back!</p>
            </div>
            <div className={styles.loginInputFields}>
              <div
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
                className={styles.label}
              >
                <input
                  className={styles.input}
                  type="text"
                  name="email"
                  placeholder="Email"
                  onChange={(e) => {
                    setUserData({ ...userData, email: e.target.value });
                  }}
                />
              </div>

              <div
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
                className={styles.label}
              >
                <input
                  className={styles.input}
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={(e) => {
                    setUserData({
                      ...userData,
                      password: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div
              name="remember"
              valuePropName="checked"
              className={styles.RememberMe}
            >
              <input type="checkbox" />
              <label>Remember me</label>
            </div>

            <div className={styles.signedUpLink}>
              <NavLink to="/Signup">Not yet signed up?</NavLink>
            </div>

            <div>
              <button
                type="submit"
                htmlType="submit"
                className={styles.loginFormButton}
              >
                LOGIN
              </button>{" "}
              <button
                onClick={forgotPassword}
                className={styles.resetPasswordBtn}
              >
                Forgot Password?
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
